<template>
  <b-form-group
    label="Kullanıcı Tipi"
    label-for="id_user_types"
  >
    <validation-provider
      #default="{ errors }"
      name="Kullanıcı Tipi"
      rules="required"
    >
      <template v-if="userData.id_dealers">
        <v-select
          v-model="dataItem.id_dealer_user_types"
          :options="userTypes"
          label="title"
          :reduce="type => type.id"
          placeholder="Seçiniz"
        />
      </template>
      <template v-else>
        <v-select
          v-model="dataItem.id_user_types"
          :options="userTypes"
          label="title"
          :reduce="type => type.id"
          placeholder="Seçiniz"
        />
      </template>

      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
import {
  BFormGroup,
} from 'bootstrap-vue'
import { required } from '@validations'
import vSelect from 'vue-select'

export default {
  name: 'UserType',
  components: {
    BFormGroup,
    vSelect,
    ValidationProvider,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      required,
    }
  },
  computed: {
    dataItem() {
      if (this.userData.id_dealers) {
        return this.$store.getters['dealerUsers/dataItem']
      }
      return this.$store.getters['users/dataItem']
    },
    userTypes() {
      if (this.userData.id_dealers) {
        return this.$store.getters['dealerUserTypes/dataList']
      }
      return this.$store.getters['userTypes/dataList']
    },
  },
  created() {
    this.getUserTypes()
    localize('tr')
  },
  methods: {
    getUserTypes() {
      if (this.userData.id_dealers) {
        this.$store.dispatch('dealerUserTypes/getDataList', {
          select: [
            'dealer_user_types.id AS id',
            'dealer_user_types.title AS title',
          ],
        })
      } else {
        this.$store.dispatch('userTypes/getDataList', {
          select: [
            'user_types.id AS id',
            'user_types.title AS title',
          ],
        })
      }
    },
  },
}
</script>
