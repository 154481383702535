<template>
  <b-card>
    <b-row>
      <b-col
        v-if="!userData.id_dealers"
        cols="12"
      >
        <user-brands />
      </b-col>
      <b-col
        cols="12"
      >
        <user-name />
      </b-col>
      <b-col
        v-if="userData.id_dealers"
        cols="12"
      >
        <user-phone />
      </b-col>
      <b-col
        cols="12"
      >
        <user-type />
      </b-col>
      <b-col
        cols="12"
      >
        <user-status />
      </b-col>
      <b-col
        cols="12"
      >
        <user-email />
      </b-col>
    </b-row>
    <user-password />
  </b-card>
</template>

<script>
import { BCard, BRow, BCol } from 'bootstrap-vue'
import UserName from '@/views/Admin/Users/elements/UserName.vue'
import UserEmail from '@/views/Admin/Users/elements/UserEmail.vue'
import UserPassword from '@/views/Admin/Users/elements/UserPassword.vue'
import UserType from '@/views/Admin/Users/elements/UserType.vue'
import UserStatus from '@/views/Admin/Users/elements/UserStatus.vue'
import UserPhone from '@/views/Admin/Users/elements/UserPhone.vue'
import UserBrands from '@/views/Admin/Users/elements/UserBrands.vue'

export default {
  name: 'UserForm',
  components: {
    BCard,
    BRow,
    BCol,
    UserName,
    UserEmail,
    UserPassword,
    UserType,
    UserStatus,
    UserPhone,
    UserBrands,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
